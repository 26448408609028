<template>
  <div class="card">
    <div class="card-header bg-white border-bottom-str">
      <h6 class="card-title font-weight-semibold">Form Kajian Fibrinolisis</h6>
    </div>
    
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
              <div class="input-group mb-3">
              <datepicker :disabledDates="disabledDates" input-class="form-control transparent h-38" placeholder="Tanggal"
                  class="my-datepicker" calendar-class="my-datepicker_calendar"
                  v-model="rowData.arankpks_date">
              </datepicker>

              <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="rowData.arankpks_jam">
              </vue-timepicker>
              <div class="input-group-append calendar-group">
                  <span class="input-group-text" id="basic-addon2"><i
                      class="icon-calendar"></i></span>
              </div>
              </div>
              <VValidate 
                  :name="'Tanggal'" 
                  v-model="rowData.arankpks_date" 
                  :rules="{required : 1}"
                  class="d-block"
              />
              <VValidate :name="'Jam'" v-model="rowData.arankpks_jam"
                  :rules="{required: 1}" />
            </div>
          </div>
        </div>
        
        
        <div class="row">
          <div class="col-12 mt-2">
            <div class="table-responsive">
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th rowspan="2">Monitoring</th>
                    <th colspan="6" class="text-center">Waktu Pemberian Fibrinolisis</th>
                  </tr>
                  <tr>
                    <th>Pre Fibrinolisis</th>
                    <th>15 Menit</th>
                    <th>30 Menit</th>
                    <th>45 Menit</th>
                    <th>60 Menit</th>
                    <th>1 Jam Post Fibrinolisis</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in (rowData.arankpksm_data||[])" :key="k">
                    <td>{{v.label}}</td>
                    <template v-if="v.type == 'radio'">
                      <td>
                        <div>
                          <b-form-radio-group
                            class="custom-block"
                            :options="Config.mr.yesNoOptV2"
                            v-model="v.data.pre"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <b-form-radio-group
                            class="custom-block"
                            :options="Config.mr.yesNoOptV2"
                            v-model="v.data.s_15"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <b-form-radio-group
                            class="custom-block"
                            :options="Config.mr.yesNoOptV2"
                            v-model="v.data.s_30"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <b-form-radio-group
                            class="custom-block"
                            :options="Config.mr.yesNoOptV2"
                            v-model="v.data.s_45"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <b-form-radio-group
                            class="custom-block"
                            :options="Config.mr.yesNoOptV2"
                            v-model="v.data.s_60"
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <b-form-radio-group
                            class="custom-block"
                            :options="Config.mr.yesNoOptV2"
                            v-model="v.data.s_1jam"
                          />
                        </div>
                      </td>
                    </template>
                    
                    <template v-else-if="v.type == 'td'">
                      <td>
                        <div class="input-group mb-2">
                          <b-form-input :formatter="number" v-model="v.data.pre_min" type="text" name="name" class="form-control" placeholder="Sistolik" />
                        </div>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.pre_max" type="text" class="form-control" placeholder="Diastolik" />
                        </div>
                      </td>
                      <td>
                        <div class="input-group mb-2">
                          <b-form-input :formatter="number" v-model="v.data.s_15_min" type="text" name="name" class="form-control" placeholder="Sistolik" />
                        </div>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_15_max" type="text" class="form-control" placeholder="Diastolik" />
                        </div>
                      </td>
                      <td>
                        <div class="input-group mb-2">
                          <b-form-input :formatter="number" v-model="v.data.s_30_min" type="text" name="name" class="form-control" placeholder="Sistolik" />
                        </div>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_30_max" type="text" class="form-control" placeholder="Diastolik" />
                        </div>
                      </td>
                      <td>
                        <div class="input-group mb-2">
                          <b-form-input :formatter="number" v-model="v.data.s_45_min" type="text" name="name" class="form-control" placeholder="Sistolik" />
                        </div>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_45_max" type="text" class="form-control" placeholder="Diastolik" />
                        </div>
                      </td>
                      <td>
                        <div class="input-group mb-2">
                          <b-form-input :formatter="number" v-model="v.data.s_60_min" type="text" name="name" class="form-control" placeholder="Sistolik" />
                        </div>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_60_max" type="text" class="form-control" placeholder="Diastolik" />
                        </div>
                      </td>
                      <td>
                        <div class="input-group mb-2">
                          <b-form-input :formatter="number" v-model="v.data.s_1jam_min" type="text" name="name" class="form-control" placeholder="Sistolik" />
                        </div>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_1jam_max" type="text" class="form-control" placeholder="Diastolik" />
                        </div>
                      </td>
                    </template>

                    <template v-else-if="v.type == 'nadi'">
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.pre_val"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          <div class="input-group-append">
                            <div style="width: 140px;">
                              <v-select placeholder="Pilih Label" v-model="v.data.pre_label"
                                :options="Config.mr.StatusRegular" label="text"
                                :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_15_val"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          <div class="input-group-append">
                            <div style="width: 140px;">
                              <v-select placeholder="Pilih Label" v-model="v.data.s_15_label"
                                :options="Config.mr.StatusRegular" label="text"
                                :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_30_val"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          <div class="input-group-append">
                            <div style="width: 140px;">
                              <v-select placeholder="Pilih Label" v-model="v.data.s_30_label"
                                :options="Config.mr.StatusRegular" label="text"
                                :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_45_val"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          <div class="input-group-append">
                            <div style="width: 140px;">
                              <v-select placeholder="Pilih Label" v-model="v.data.s_45_label"
                                :options="Config.mr.StatusRegular" label="text"
                                :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_60_val"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          <div class="input-group-append">
                            <div style="width: 140px;">
                              <v-select placeholder="Pilih Label" v-model="v.data.s_60_label"
                                :options="Config.mr.StatusRegular" label="text"
                                :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_1jam_val"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                          <div class="input-group-append">
                            <div style="width: 140px;">
                              <v-select placeholder="Pilih Label" v-model="v.data.s_1jam_label"
                                :options="Config.mr.StatusRegular" label="text"
                                :clearable="true" :reduce="v=>v.value"></v-select>
                            </div>
                          </div>
                        </div>
                      </td>
                    </template>

                    <template v-else-if="v.type == 'nafas'">
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.pre"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_15"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_30"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_45"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_60"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_1jam"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">x/mnt</span></div>
                        </div>
                      </td>
                    </template>

                    <template v-else-if="v.type == 'spo2'">
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.pre"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">%</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_15"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">%</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_30"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">%</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_45"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">%</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_60"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">%</span>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div class="input-group">
                          <b-form-input :formatter="number" v-model="v.data.s_1jam"
                            type="text" class="form-control" />
                          <div class="input-group-append"><span class="input-group-text">%</span>
                          </div>
                        </div>
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>


        <div class="card mb-0">
          <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Obat Fibrinolisis</h6>
          </div>
          <div class="card-body">
            <div class="row mt-2">
              <table class="table table-bordered table-sm patient-table">
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Nama Obat</th>
                    <th>Dosis</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(v,k) in (rowData.arankpksm_obat||[])" :key="k">
                    <td>{{k+1}}</td>
                    <td>
                      <b-form-input v-model="v.nama" type="text" class="form-control" />
                      <VValidate :name="'Nama #'+(k+1)" v-model="v.nama" :rules="{required: 1}" />   
                      
                    </td>
                    <td>
                      <b-form-input v-model="v.dosis" type="text" class="form-control" />
                      <VValidate :name="'Dosis #'+(k+1)" v-model="v.dosis" :rules="{required: 1}" />
                    </td>
                    <td>
                      <a href="javascript:;" class="list-icons-item"
                        @click="rowData.arankpksm_obat.splice(k,1)"
                        data-toggle="tooltip" data-placement="top" title="Delete"><i
                          class="icon-bin"></i></a>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="4" class="text-center">
                                
                      <b-button @click="rowData.arankpksm_obat.push({
                        'nama': null,
                        'dosis': null
                      })" class="btn bg-blue-400 btn-labeled btn-labeled-left ml-1">
                      <b><i class="icon-plus2"></i></b> Tambah
                      </b-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="card mb-0">
          <div class="card-header bg_head_panel">
          <h6 class="card-title font-weight-semibold">Tindakan Lanjutan</h6>
          </div>
          <div class="card-body">
              <div class="row mt-2">
                  <div class="col-md-5">
                      <label for="">Jadwal Monitoring <span class="text-danger">*</span></label>
                      <b-form-radio-group
                          :disabled="rowData.arankpks_is_parent == 'Y'"
                          :options="Config.mr.lanjutanMonitoring"
                          v-model="rowData.arankpks_is_next_monitoring"
                      />
                      <VValidate :name="'Jadwal Monitoring'" v-model="rowData.arankpks_is_next_monitoring" :rules="{required: 1}" />
                  </div>

                  <div class="col-md-5" v-if="rowData.arankpks_is_next_monitoring == 'Y'">
                      <label>Interval Waktu<small class="txt_mandatory">*</small></label>
                      <div class="form-row">
                      <div class="col-md-5">
                          <v-select placeholder="-- Pilih Salah Satu -- " v-model="rowData.arankpksm_label" :options="isParent.Config.mr.waktuKajianInterval" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                          
                          <VValidate 
                              :name="'Interval Waktu'" 
                              v-model="rowData.arankpksm_label" 
                              :rules="toValidate(mrValidation.arankpksm_label)"
                              class="d-block"
                          />
                      </div>
                      <div class="col-md-7" v-if="rowData.arankpksm_label == 999999">
                          <div class="form-group">
                          <div class="input-group">
                          <b-form-input :formatter="numberOnly" v-model="rowData.arankpksm_waktu" type="text" class="form-control" />
                          <div class="input-group-append">
                              <div style="width: 120px;">                                
                              <v-select placeholder="-- Pilih -- " v-model="rowData.arankpksm_waktu_label" :options="isParent.Config.mr.ketWaktuInterval" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                              </div>
                          </div>
                          
                          <VValidate 
                              :name="'Interval Waktu Lainnya'" 
                              v-model="rowData.arankpksm_waktu" 
                              :rules="toValidate(mrValidation.arankpksm_waktu)"
                              class="d-block"
                          />
                          
                          <VValidate 
                              :name="'Keterangan Waktu'" 
                              v-model="rowData.arankpksm_waktu_label" 
                              :rules="toValidate(mrValidation.arankpksm_waktu_label)"
                              class="d-block"
                          />
                      </div>
                          </div>
                      </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <div class="text-right">
            <button type="button" @click="back()" class="btn btn-light mr-2">Back</button>
            <button type="button" class="btn btn-info mr-2" @click="doSave()">Simpan Sementara <i class="icon-paperplane mr-2"></i></button>
            <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane mr-2"></i></button>
        </div>
      </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  extends: GlobalVue,
  components: {Datepicker,VueTimepicker}, 
  computed: {
    isParent(){
      return this.$parent
    },
  },
  
  data() {
    return {
      rowData: {},
      isMonitoring: false,
    }
  },

  methods: {
    back() {
      this.$router.back()
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.kajianNo+'?monitoring=1'
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.kajianNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },

    
    doSave(){
      let data = this.rowData
      data.arankpks_updated_by = this.user.id
      data.arankpks_updated_date = moment().format("YYYY-MM-DD HH:mm:ss")
      data.type = 'auto-save'

      this.loadingOverlay = true

      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.loadingOverlay = false
        
        let resp = res.data
        resp.statusType = 200
        this.$swal({
            title: "Data Berhasil Disimpan",
            icon: "success",
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
        })
      })
    },
    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            text: "Pastikan Jadwal Monitoring yang diiisi telah sesuai",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data-monitoring'
              data.resepObat = this.resepObat
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                  }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                  })
              })
            }
          })
        }
      })
    }
  },
  
  mounted() {
    this.apiGetForm()
  },
  
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  }
}
</script>