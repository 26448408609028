<template>
  <div class="card">
    <div class="card-header bg-white border-bottom-str">
      <h6 class="card-title font-weight-semibold">Form Kajian Fibrinolisis</h6>
    </div>
    
    <validation-observer ref="VForm">
      <b-form @submit.prevent="doSubmit">
        <div class="card-body">
        
          <div class="row">
            <div class="col-md-6 col-lg-4">
              <div class="form-group">
                <label for="kenyamananNyeri">Tanggal <span class="text-danger">*</span></label>
                <div class="input-group mb-3">
                <datepicker :disabledDates="disabledDates" input-class="form-control transparent h-38" placeholder="Tanggal"
                    class="my-datepicker" calendar-class="my-datepicker_calendar"
                    v-model="rowData.arankpks_date">
                </datepicker>

                <vue-timepicker manual-input format="HH:mm" placeholder="Jam" input-class="form-control h-38" v-model="rowData.arankpks_jam">
                </vue-timepicker>
                <div class="input-group-append calendar-group">
                    <span class="input-group-text" id="basic-addon2"><i
                        class="icon-calendar"></i></span>
                </div>
                </div>
                <VValidate 
                    :name="'Tanggal'" 
                    v-model="rowData.arankpks_date" 
                    :rules="{required : 1}"
                    class="d-block"
                />
                <VValidate :name="'Jam'" v-model="rowData.arankpks_jam"
                    :rules="{required: 1}" />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6 mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Kontra Indikasi Absolut</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-12">
                      <b-form-checkbox-group
                          name="absolut"
                          class="checkbox-block"
                          v-model="rowData.arankpksd_kontra_absolut"
                          :options="indikasiAbsolut"
                      ></b-form-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-6 mt-2">
              <div class="card mb-0">
                <div class="card-header bg_head_panel">
                  <h6 class="card-title font-weight-semibold">Kontra Indikasi Relatif</h6>
                </div>
                <div class="card-body p-3">
                  <div class="row">
                    <div class="col-md-12">
                      
                      <b-form-checkbox-group
                          name="relative"
                          class="checkbox-block"
                          v-model="rowData.arankpksd_kontra_relatif"
                          :options="indikasiRelatif"
                      ></b-form-checkbox-group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="card mb-0">
              <div class="card-header bg_head_panel">
              <h6 class="card-title font-weight-semibold">Tindakan Lanjutan</h6>
              </div>
              <div class="card-body">
                  <div class="row mt-2">
                      <div class="col-md-5">
                          <label for="">Jadwal Monitoring <span class="text-danger">*</span></label>
                          <b-form-radio-group
                              :disabled="rowData.arankpks_is_parent == 'Y'"
                              :options="Config.mr.lanjutanMonitoring"
                              v-model="rowData.arankpks_is_next_monitoring"
                          />
                          <VValidate :name="'Jadwal Monitoring'" v-model="rowData.arankpks_is_next_monitoring" :rules="{required: 1}" />
                      </div>

                      <div class="col-md-5" v-if="rowData.arankpks_is_next_monitoring == 'Y'">
                          <label>Interval Waktu<small class="txt_mandatory">*</small></label>
                          <div class="form-row">
                          <div class="col-md-5">
                              <v-select placeholder="-- Pilih Salah Satu -- " v-model="rowData.arankpksd_label" :options="isParent.Config.mr.waktuKajianInterval" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                              
                              <VValidate 
                                  :name="'Interval Waktu'" 
                                  v-model="rowData.arankpksd_label" 
                                  :rules="toValidate(mrValidation.arankpksd_label)"
                                  class="d-block"
                              />
                          </div>
                          <div class="col-md-7" v-if="rowData.arankpksd_label == 999999">
                              <div class="form-group">
                              <div class="input-group">
                              <b-form-input :formatter="numberOnly" v-model="rowData.arankpksd_waktu" type="text" class="form-control" />
                              <div class="input-group-append">
                                  <div style="width: 120px;">                                
                                  <v-select placeholder="-- Pilih -- " v-model="rowData.arankpksd_waktu_label" :options="isParent.Config.mr.ketWaktuInterval" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                                  </div>
                              </div>
                              
                              <VValidate 
                                  :name="'Interval Waktu Lainnya'" 
                                  v-model="rowData.arankpksd_waktu" 
                                  :rules="toValidate(mrValidation.arankpksd_waktu)"
                                  class="d-block"
                              />
                              
                              <VValidate 
                                  :name="'Keterangan Waktu'" 
                                  v-model="rowData.arankpksd_waktu_label" 
                                  :rules="toValidate(mrValidation.arankpksd_waktu_label)"
                                  class="d-block"
                              />
                          </div>
                              </div>
                          </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        </div>
        <div class="card-footer">
          <div class="text-right">
              <button type="button" @click="back()" class="btn btn-light mr-2">Back</button>
              <button type="button" class="btn btn-info mr-2" @click="doSave()">Simpan Sementara <i class="icon-paperplane mr-2"></i></button>
              <button type="submit" class="btn btn-primary">Submit <i class="icon-paperplane mr-2"></i></button>
          </div>
        </div>
      </b-form>
    </validation-observer>
  </div>
</template>


<script>
import $ from 'jquery'
const _ = global._

import Gen from '@/libs/Gen.js'
import GlobalVue from '@/libs/Global.vue'
const moment = require('moment')
import Datepicker from 'vuejs-datepicker'
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'


export default{
  extends: GlobalVue,
  components: {Datepicker,VueTimepicker}, 
  computed: {
    isParent(){
      return this.$parent
    },
  },
  data() {
    return {
      rowData: {},
      isMonitoring: false,
      indikasiRelatif: [],
      indikasiAbsolut: [],

    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    apiGetForm(params = {}, page = 1){
      if(!this.pageSlug){
          this.$router.push({name : 'Dashboard'}).catch(()=>{})
      }

      if(this.pageSlug || this.$route.name=='Dashboard') this.loadingOverlay = true
      this.data.data = false
      let paramsQuery = Object.keys(this.$route.query).length ? this.apiParams : this.$route.query
      let url = this.modulePage+'/'+this.$route.params.pageSlug+'/'+this.$route.params.kajianNo
      
      Gen.apiRest(
        "/get/"+url, 
        {
        params: Object.assign({page: page}, paramsQuery, params.query||{})
        }
      ).then(res=>{
          this.loadingOverlay = false
          _.forEach(res.data, (v,k)=>{
              this.$set(this, k, v)
          })
          if(!this.isFound){
              this.$router.push({name : 'Dashboard'}).catch(()=>{})
          }
      }).catch(()=>{
          this.loadingOverlay = false
      })

      // master
      if(this.$route.params.kajianNo){
        Gen.apiRest(
          "/get/"+url+'?master=1', 
          {
          params: Object.assign({page: page}, paramsQuery, params.query||{})
          }
        ).then(res=>{
          _.forEach(res.data, (v,k)=>{
            this.$set(this, k, v)
          })
        })
      }    
    },
    toValidate(val){
      return {...val}
    },        
    getConfigDynamic(master, value) {
      let text = ''
      if (value) {
        let index = (master||[]).findIndex(x => x.value == value)
        if (index !== -1) {
            text = master[index]['text']
        }
      }
      return text
    },
    autoSave: _.debounce(function (data) {
      data.type = 'auto-save'
      if(!data.isEdit){
        Gen.apiRest(
            "/do/"+this.modulePage,
            {data:data}, 
            "POST"
        )
      }
    },1000),

    doSave(){
      let data = this.rowData
      data.arankpks_updated_by = this.user.id
      data.arankpks_updated_date = moment().format("YYYY-MM-DD HH:mm:ss")
      data.type = 'auto-save'

      this.loadingOverlay = true

      Gen.apiRest(
      "/do/" + this.modulePage, {
        data: data
      },
      "POST"
      ).then(res => {
        this.loadingOverlay = false
        
        let resp = res.data
        resp.statusType = 200
        this.$swal({
            title: "Data Berhasil Disimpan",
            icon: "success",
            confirmButtonText: 'Ok',
            allowOutsideClick: false,
            allowEscapeKey: false
        }).then(result => {
            this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
        })
      })
    },

    
    doSubmit(){
      this.$refs['VForm'].validate().then(success => {
        if(!(this.rowData.arankpksd_kontra_absolut||[]).length && !(this.rowData.arankpksd_kontra_relatif||[]).length){
          return this.$swal({
            icon: 'error',
            title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          })
        }

        if (!success) {
          return this.$swal({
              icon: 'error',
              title: 'Data Tidak Lengkap, Mohon Lengkapi Data Terlebih Dahulu'
          }).then(result => {
              if (result.value) {
                  setTimeout(() => {
                      let inv = []
                      let el = document.querySelectorAll(".label_error:not([style='display: none;'])")
                      for (let i = 0; i < (el || []).length; i++) {
                          if (el[i].style.display !== 'none') {
                          inv.push(el[i].id)
                          }
                      }
                                  
                      if (inv.length) document.getElementById(inv[0]).scrollIntoView({
                          behavior: 'smooth',
                          block: 'center'
                      })
                  }, 500)
              }
          })
        }
        if (success) {
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda Yakin akan menyimpan data ini?',
            text: "Pastikan Jadwal Monitoring yang diiisi telah sesuai",
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if (result.value) {
              let data = this.rowData
              data.type = 'submit-data'
              this.loadingOverlay = true
              Gen.apiRest(
              "/do/" + this.modulePage, {
                  data: data
              },
              "POST"
              ).then(res => {
                  this.loadingOverlay = false
                  let resp = res.data
                  resp.statusType = 200
                  this.$swal({
                      title: resp.message,
                      icon: resp.status,
                      confirmButtonText: 'Ok',
                      allowOutsideClick: false,
                      allowEscapeKey: false
                  }).then(result => {
                      this.$router.push({ name: this.modulePage, params: {pageSlug: this.pageSlug}}).catch(()=>{})   
                  }).catch(err => {
                    this.loadingOverlay = false
                    if (err) {
                      err.statusType = err.status
                      err.status = "error"
                      err.title = err.response?.data?.title
                      err.message = err.response?.data?.message
                      err.messageError = err.message
                    }
                    this.doSetAlertForm(err)
                  })
              })
            }
          })
        }
      })
    }

  },
  mounted() {
    this.apiGetForm()
  },
  watch: {
    rowData: {
      handler(v) {
        this.autoSave(v)
      },
      deep: true
    },
  }
}

</script>